<template>
  <!-- simula tab content -->
  <div class="col-12">
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" role="tabpanel">
        <!-- 1st row -->
        <div class="row">
          <div class="col-sm-4">
            <label
              >Valor de la {{ destino == 1 ? "vivienda" : "propiedad" }}</label
            >
            <div class="flex">
              <input
                type="text"
                class="form-control"
                placeholder="$"
                v-model="cotizacion.valor"
                v-validate="'max:13'"
                name="valor"
                v-on:keypress="isNumber($event)"
              />
            </div>
          </div>

          <div class="col-sm-4">
            <label>Monto Cr&eacute;dito</label>
            <div class="flex">
              <input
                type="text"
                class="form-control"
                placeholder="$"
                v-model="cotizacion.credito"
                v-validate="'max:13'"
                name="credito"
                v-on:keypress="isNumber($event)"
              />
            </div>
          </div>

          <div v-if="destino != 19" class="col-sm-4">
            <label>Plazos a cotizar</label>
            <div class="flex">
              <select
                v-model="cotizacion.plazo"
                class="form-select custom-select-single-simula"
              >
                <option
                  v-bind:key="plazo.value"
                  :value="plazo.value"
                  v-for="plazo in plazos"
                >
                  {{ plazo.label }}
                </option>
              </select>
            </div>
          </div>

          <div v-if="destino == 19" class="col-sm-4">
            <label>Pago mensual</label>
            <div class="flex">
              <input
                type="text"
                class="form-control"
                placeholder="$"
                v-model="cotizacion.pagoMensual"
                v-validate="'max:13'"
                name="pagoMensual"
                v-on:keypress="isNumber($event)"
              />
            </div>
          </div>
        </div>

        <!-- hidden row -->
        <div v-if="destino == 19" class="row mt-8">
          <div class="col-sm-4">
            <label>Plazos a cotizar</label>
            <div class="flex">
              <select
                v-model="cotizacion.plazo"
                class="form-select custom-select-single-simula"
              >
                <option
                  v-bind:key="plazo.value"
                  :value="plazo.value"
                  v-for="plazo in plazos"
                >
                  {{ plazo.label }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-sm-8">
            <label>Tiempo restante del crédito</label>
            <div class="flex">
              <select
                v-model="cotizacion.plazoRestante"
                class="form-select custom-select-single-simula"
              >
                <option
                  v-bind:key="plazoRestante.value"
                  :value="plazoRestante.value"
                  v-for="plazoRestante in plazosRestantes"
                >
                  {{ plazoRestante.label }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- 2nd row -->
        <div class="row mt-8">
          <div class="col-sm-12">
            <label>Bancos</label>
            <v-select
              class="bg-white custom-select-multiple-simula"
              v-model="cotizacion.bancos"
              multiple
              :closeOnSelect="false"
              :options="bancos"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />
          </div>
        </div>

        <!-- 3rd row -->
        <div class="row mt-8">
          <div class="flex justify-end gap-4">
            <button
              type="button"
              class="btn btn-success w-64"
              @click.prevent="seleccionarTodo()"
            >
              Seleccionar todo
            </button>
            <button
              type="button"
              class="btn btn-success w-64"
              @click.prevent="limpiarSeleccion()"
            >
              Limpiar selección
            </button>
          </div>
        </div>

        <!-- 4to row -->
        <div class="row mt-24">
          <div class="flex justify-end">
						<button
							@click="mostrarCliente()"
							:disabled="!validaFormulario"
							type="button"
							class="btn btn-blue custom-btn-blue-simula w-64"
						>
							Siguiente
						</button>
          </div>
        </div>

        <!-- {{ cotizacion }} -->

      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  name: "CalculadoraCredito",
  components: {
    vSelect,
  },
  props: {
    destino: {
      type: Number,
      required: true,
      default: 1,
    },
    // caso: {
    //   type: Object,
    //   required: false,
    // },
  },
  data() {
    return {
      cotizacion: {
        destino: "",
        valor: "1,000,000",
        credito: "700,000",
        plazo: "20",
        pagoMensual: "",
        plazoRestante: "",
        bancos: [],
        esquema: ["2","1"],
      },
      bancos: [],
      // sin peticion api, manejo anterior
      plazos: [
        { label: "20 Años", value: "20" },
        { label: "15 Años", value: "15" },
        { label: "10 Años", value: "10" },
        { label: "5 Años", value: "5" },
      ],
      plazosRestantes: [
        { label: "20 Años", value: "20" },
        { label: "19 Años", value: "19" },
        { label: "18 Años", value: "18" },
        { label: "17 Años", value: "17" },
        { label: "16 Años", value: "16" },
        { label: "15 Años", value: "15" },
        { label: "14 Años", value: "14" },
        { label: "13 Años", value: "13" },
        { label: "12 Años", value: "12" },
        { label: "11 Años", value: "11" },
        { label: "10 Años", value: "10" },
        { label: "9 Años", value: "9" },
        { label: "8 Años", value: "8" },
        { label: "7 Años", value: "7" },
        { label: "6 Años", value: "6" },
        { label: "5 Años", value: "5" },
        { label: "4 Años", value: "4" },
        { label: "3 Años", value: "3" },
        { label: "2 Años", value: "2" },
        { label: "1 Años", value: "1" },
      ],
      esquemas: [
        { label: "Pago Fijo", value: "2" },
        { label: "Pago creciente", value: "1" },
      ],
    };
  },
  mounted() {
		// console.log( 'CreandoCalculadoraCredito', localStorage.getItem('calculadoraDestino') );
		// console.log( 'CreandoCalculadoraCredito' );
    this.cotizacion.destino = this.destino; // cuando no hay data en localstorage
		this.getDestinoData();
    this.getApikey();
    this.getBancos();

		// // provisional
		// if (Object.keys(this.caso).length > 0) {
		// 	// indica que se uso el boton regresar, se tiene que recargar this.cotizacion
		// 	this.cotizacion = this.caso; // usa los dato del padre
		// }
  },
	computed: {
		validaFormulario() {
			if (this.destino == 19) {
				return this.cotizacion.valor.length > 0 && this.cotizacion.credito.length > 0 && this.cotizacion.pagoMensual.length > 0 && this.cotizacion.plazoRestante.length > 0 && this.cotizacion.plazo.length > 0 && this.cotizacion.bancos.length > 0;
			} else {
				return this.cotizacion.valor.length > 0 && this.cotizacion.credito.length > 0 && this.cotizacion.plazo.length > 0 && this.cotizacion.bancos.length > 0;
			}
		}
	},
  watch: {
    "cotizacion.valor": function (newValue) {
      this.cotizacion.valor = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    "cotizacion.credito": function (newValue) {
      this.cotizacion.credito = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    destino: function (newValue) {
      this.cotizacion.destino = newValue;
    },
  },
  methods: {
    getApikey() {
      if (!JSON.parse(localStorage.getItem("userInfo")).ApiKey) {
        this.$router.push("/login");
      } else {
        this.key = JSON.parse(localStorage.getItem("userInfo")).ApiKey;
      }
    },
		getDestinoData() {
			// si existe datos en localstorage
			if (JSON.parse(localStorage.getItem("calculadoraDestino"))) {
				let dataLocalStorage = JSON.parse(localStorage.getItem("calculadoraDestino"));
				this.cotizacion = dataLocalStorage;
				this.destino = dataLocalStorage.destino;
      }
		},
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    seleccionarTodo() {
      this.cotizacion.bancos = this.bancos;
    },
    limpiarSeleccion() {
      this.cotizacion.bancos = [];
    },
    getBancos() {
      let self = this;
      let objRequestListaFinancieras = {
        strApiKey: this.key,
        strMetodo: "ListaFinancieras",
        objEjecutivo: {
          EMail: this.$store.state.AppActiveUser.EMail,
        },
      };

      this.$axios
        .post("/", objRequestListaFinancieras, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((response) => {
          if (response.data.intEstatus == 0) {
            let bancos = response.data.objContenido;

            bancos.forEach((banco) => {
              this.bancos.push({
                label: banco.Nombre,
                value: banco.Id,
              });
            });
          } else {
            this.$vs.notify({
              title: "Ocurrio un error en ListaFinancieras",
              text: response.data.strError,
              color: "danger",
              position: "top-right",
            });
          }
        })
        .catch(function (error) {
          self.$vs.notify({
            title: "Ocurrio un error de sistema",
            text: error,
            color: "danger",
            position: "top-right",
          });
        });
    },
		mostrarCliente() {
			this.$emit('mostrarCliente', this.cotizacion);
			// console.log( 'calculadoraCredito', this.cotizacion );
		}
		// actualizarCotizacion() {
		// 	this.cotizacion.mostrarFormCliente = true;
		// 	this.cotizacion.tituloSeccion = 'Datos de tu cliente';
		// 	this.$emit('actualizarCotizacion',this.cotizacion);

		// 	console.log( 'calculadoraCredito', this.cotizacion );
		// }
  },
};
</script>

<style lang="css">
.custom-select-single-simula {
  padding: 0.35rem !important;
}
.custom-btn-blue-simula {
  color: #fff;
  background-color: #079def !important;
  border-color: #079def;
  margin-left: 0px !important;
}
.custom-select-multiple-simula ul {
  max-height: 200px;
}
.custom-select-multiple-simula ul > li {
  font-size: 1rem;
}
</style>